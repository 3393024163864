<template>
  <section class="reclassification-wrap">
    <h2 class="title">分类目标</h2>
    <el-form :model="form" label-width="110px">
      <el-form-item label="文章发布时间" prop="releaseTime">
        <el-date-picker
          unlink-panels
          v-model="form.releaseTime"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="分类依据关键词" prop="basis">
        <el-radio-group v-model="form.type">
          <el-radio label="1">精确匹配</el-radio>
          <el-radio label="2">分词后再匹配</el-radio>
        </el-radio-group>
      </el-form-item>
<!--      <el-form-item label="主题目录" prop="directory">-->
<!--        <el-input type="text" v-model="form.region"></el-input>-->
<!--        &lt;!&ndash; <el-select v-model="form.region" placeholder="主题目录">-->
<!--          <el-option v-for="item in directory" :key="item.id" :label="item.label" :value="item.label"></el-option>-->
<!--        </el-select> &ndash;&gt;-->
<!--      </el-form-item>-->
      <el-form-item label="主题范围" prop="msIds">
        <el-checkbox-group v-model="form.msIds" class="subjectArea" v-loading="msLoading">
          <el-checkbox v-for="m in monitor" :label="m.id" name="subject">{{ m.title }}</el-checkbox>
        </el-checkbox-group>
<!--        <el-checkbox class="recent" v-model="form.checked">-->
<!--          只分类-->
<!--          <el-select v-model="value" size="mini" class="dateSelect">-->
<!--              <el-option-->
<!--                v-for="item in datetime"-->
<!--                :key="item.id"-->
<!--                :label="item.label"-->
<!--                :value="item.label">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--          修改过的主题-->
<!--        </el-checkbox>-->
        <div class="btnGroup">
          <el-button size="mini" @click="selectAll">全选</el-button>
          <el-button size="mini" @click="reverse">反选</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="submit">开始重新分类</el-button>
      </el-form-item>
    </el-form>
    <!-- <div class="pro-wrapper">
      <el-progress class="progress" :text-inside="true" :stroke-width="12" :percentage="70"></el-progress>
    </div> -->
    <!-- <section class="result">
      <h2 class="title">分类结果</h2>
      <p class="content"></p>
    </section> -->
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { selectAllType } from '@/api/dataOutput'
import { reClass, stopReClassTask } from '@/api/detection'
export default {
  mounted () {
    eventHub.$emit('msg', '重新分类')
    this.listAllMS()
  },
  data () {
    return ({
      msLoading: true,
      value: '',
      monitor: [
      ],
      form: {
        releaseTime: '',
        type: '1',
        directory: '',
        msIds: [],
        checked: false
      },
      datetime: [
        {
          id: 1,
          label: '最近3小时'
        },
        {
          id: 2,
          label: '最近5小时'
        },
        {
          id: 3,
          label: '最近24小时'
        }
      ],
      directory: [
        {
          id: 1,
          label: '主题1'
        },
        {
          id: 2,
          label: '主题2'
        }
      ]
    })
  },
  methods: {
    async listAllMS () {
      const res = await selectAllType()
      this.monitor = res.data
      this.msLoading = false
    },
    async submit () {
      if (this.form.msIds.length === 0) {
        this.$message.warning('您必须选择至少一个主题进行重新分类')
        return
      }
      const res = await reClass({ msIds: this.form.msIds, type: this.form.type, startingTime: this.form.releaseTime[0], endTime: this.form.releaseTime[1] })
      if (res.code === 0) {
        this.$message.success('开始进行重新分类，请稍候刷新查看')
      } else {
        if (res.msg === '正在重新分类中，请等待当前任务执行完毕后再重试') {
          this.$notify.error({
            title: '错误',
            dangerouslyUseHTMLString: true,
            message: '<a style="text-decoration-line: underline">' + res.msg + ',<a style="color: red;text-decoration-line: underline">或者点击此消息以结束当前任务</a></a>',
            onClick () {
              stopReClassTask()
              this.$message.info('当前任务已结束，您可选择其它主题进行重新分类')
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      }
    },
    selectAll () {
      var itemArray = document.getElementsByClassName('subjectArea')[0].getElementsByClassName('el-checkbox')
      for (var i = 0; i < itemArray.length; i++) {
        let item = itemArray[i]
        item.classList.add('is-checked')
        item.getElementsByClassName('el-checkbox__input')[0].classList.add('is-checked')
      }
      this.form.msIds = []
      for (var j = 0; j < this.monitor.length; j++) {
        this.form.msIds.push(this.monitor[j].id)
      }
    },
    reverse () {
      var itemArray = document.getElementsByClassName('subjectArea')[0].getElementsByClassName('el-checkbox')
      this.form.msIds = []
      for (var i = 0; i < itemArray.length; i++) {
        let item = itemArray[i]
        if (item.classList.contains('is-checked') === true) {
          item.classList.remove('is-checked')
          item.getElementsByClassName('el-checkbox__input')[0].classList.remove('is-checked')
        } else {
          item.classList.add('is-checked')
          item.getElementsByClassName('el-checkbox__input')[0].classList.add('is-checked')
          this.form.msIds.push(item.getElementsByClassName('el-checkbox__input')[0].getElementsByTagName('input')[0].getAttribute('value'))
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .reclassification-wrap {
    width: 100%;
    min-height: 100%;
    background: #fff;
    border-radius: 2px;
    padding: 20px 20px;
    position: relative;
    .title {
      font-size: 16px;
      color: #303133;
    }
    .el-input {
      width: 380px;
    }
    .subjectArea {
      width: 380px;
      height: 323px;
      background: #F2F6FC;
      border: 1px solid #DCDFE6;
      border-radius: 4px;
      overflow-y: scroll;
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #409EFF;
      }
      &::-webkit-scrollbar{
        width:5px;
        height:10px;
      }
      &::-webkit-scrollbar-track{
        background: rgb(239, 239, 239);
        border-radius:2px;
      }
      &::-webkit-scrollbar-thumb{
        background: #bfbfbf;
        border-radius:5px;
      }
      &::-webkit-scrollbar-thumb:hover{
        background: #333;
      }
      &::-webkit-scrollbar-corner{
        background: #179a16;
      }
      .el-checkbox {
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-right: 0;
        display: block;
        background: #FFF;
        border-bottom: 1px solid #DCDFE6;
        .el-form-item__content {
          line-height: 0;
        }
        .el-checkbox__input {
          display: none;
        }
      }
    }
    .dateSelect{
      width: 35%;
      .el-input{
        width: 100%;
      }
    }
    .btnGroup{
      margin-left: 258px;
    }
    .result {
      .title {
        font-size: 18px;
      }
      .content {
        width: 100%;
        height: 400px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        overflow-y:scroll;
      }
    }
    .progress {
      width: 30%;
      font-size: 12px;
    }
  }
</style>
